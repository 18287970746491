import { AuthRole, getAuth } from "../../../../../core/auth"
import { HttpApi } from "../../../../../core/http"

interface BodyShape {
	id: string
}

export async function apiCategoryDeleteV1(payload: BodyShape) {
	const httpApi = new HttpApi("post", "category/delete/v1")

	const auth = getAuth()
	if (!auth) throw new Error("You are not authenticated")
	if (auth.role !== AuthRole.ADMIN) {
		throw new Error("Only admin can add category")
	}

	return await httpApi.send({ body: payload, bearerToken: auth.accessToken })
}
