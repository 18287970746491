import { AuthRole, getAuth } from "../../core/auth"

export function getAdminAccessToken(contextTitle?: string) {
	const auth = getAuth()
	if (!auth) throw new Error("This operation is only allowed for authenticated users")

	if (auth.role !== AuthRole.ADMIN) {
		let message = "Only admins are allowed to do this operation"
		if (contextTitle) message = `Only admins are allowed to ${contextTitle}`

		throw new Error(message)
	}

	return auth.accessToken
}
