import { FormControl, Input, Stack } from "@chakra-ui/react"
import { ComponentProps, FC } from "react"
import { useForm } from "react-hook-form"
import { DrawerForm } from "../../../common/components/DrawerForm"
import { useErrorToast, useSuccessToast } from "../../../common/hooks/toasts"
import { useAdminListData } from "../queries/adminListQuery"
import { apiAdminAddV1 } from "../apis/apiAdminAddV1"

interface FormFields {
	username: string
	password: string
}

type Props = Omit<ComponentProps<typeof DrawerForm>, "children">

export const AdminAddDrawer: FC<Props> = ({ ...rest }) => {
	const errorToast = useErrorToast()
	const successToast = useSuccessToast()
	const { refetch } = useAdminListData()

	const { register, handleSubmit, formState, reset } = useForm<FormFields>({
		defaultValues: { username: "", password: "" },
	})

	const onSubmit = handleSubmit(async (formFields) => {
		try {
			await apiAdminAddV1(formFields)
			successToast("Admin added successfully")
			refetch()
			reset()
			rest.onClose()
		} catch (err) {
			errorToast(err)
		}
	})

	return (
		<DrawerForm
			headingText="Add Admin"
			submitLabel="Save"
			onSubmit={onSubmit}
			isSubmitting={formState.isSubmitting}
			{...rest}
		>
			<Stack maxWidth={"sm"}>
				<Stack spacing={2}>
					{/* Username */}
					<FormControl>
						<label>Username</label>
						<Input isRequired autoFocus {...register("username")} />
					</FormControl>

					{/* Password */}
					<FormControl>
						<label>Password</label>
						<Input type="password" isRequired {...register("password")} />
					</FormControl>
				</Stack>
			</Stack>
		</DrawerForm>
	)
}
