import { HttpApi } from "../../../../../core/http"
import { getAdminAccessToken } from "../../../utils"
import { BrandV1 } from "../../models"

interface BodyShape {
	filter: { id: string }
	update?: { title?: string; description?: string }
}

export async function apiBrandUpdateV1(payload: BodyShape) {
	const httpApi = new HttpApi<BrandV1>("post", "productBrand/update/v1")
	const bearerToken = getAdminAccessToken("update brand")

	return await httpApi.send({ body: payload, bearerToken })
}
