import { useToast } from "@chakra-ui/react"
import { useCallback } from "react"

export function useErrorToast() {
	const toast = useToast()

	const errorToast = useCallback(
		(error: string | Error | unknown) => {
			let message = "Something went wrong"
			if (typeof error === "string") message = error
			else if (error instanceof Error) message = error.message

			toast({
				title: message,
				status: "error",
				position: "bottom-right",
				isClosable: true,
				duration: 4000,
			})
		},
		[toast],
	)

	return errorToast
}
