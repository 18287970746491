import { ComponentProps, FC } from "react"
import { NavItem } from "./NavItem"

type Props = ComponentProps<typeof NavItem>

export const SelectedNavItem: FC<Props> = (props) => (
	<NavItem
		{...props}
		bg="whiteAlpha.100"
		color="whiteAlpha.900"
		_hover={{ bg: "whiteAlpha.100", color: "whiteAlpha.900" }}
	/>
)
