import {
	Box,
	Button,
	FormControl,
	Heading,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Text,
} from "@chakra-ui/react"
import { FC } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { AuthRole, setAuth } from "../../../core/auth"
import { useErrorToast } from "../../../common/hooks/toasts/useErrorToast"
import { useSuccessToast } from "../../../common/hooks/toasts/useSuccessToast"
import { apiAdminLoginV1 } from "../apis/apiAdminLoginV1"
import { apiDeveloperLoginV1 } from "../apis/apiDeveloperLoginV1"

interface FormFields {
	username: string
	password: string
	role: AuthRole
}

export const LoginForm: FC = () => {
	const navigate = useNavigate()
	const errorToast = useErrorToast()
	const successToast = useSuccessToast()

	const { register, setValue, handleSubmit, formState } = useForm<FormFields>({
		defaultValues: { username: "", password: "", role: AuthRole.ADMIN },
	})

	const onSubmit = handleSubmit(async (formFields) => {
		try {
			const loginApiFunction =
				formFields.role === AuthRole.ADMIN ? apiAdminLoginV1 : apiDeveloperLoginV1
			const { data } = await loginApiFunction(formFields)

			setAuth({ accessToken: data.accessToken, role: formFields.role })
			navigate(`/${formFields.role.toLowerCase()}`)
			successToast("Login successful")
		} catch (err) {
			errorToast(err)
		}
	})

	return (
		<Box padding={10}>
			<Stack maxWidth={"sm"}>
				<Box textAlign={"center"}>
					<Heading as="h1" size="lg" color={"gray.700"}>
						Login
					</Heading>
					<br />
				</Box>
				<form onSubmit={onSubmit}>
					<Stack spacing={2}>
						{/* Username */}
						<FormControl>
							<label>Username</label>
							<Input isRequired autoFocus {...register("username")} />
						</FormControl>

						{/* Password */}
						<FormControl>
							<label>Password</label>
							<Input type="password" isRequired {...register("password")} />
						</FormControl>

						{/* Role */}
						<FormControl textAlign={"center"} pt={2}>
							<Text>Continue as</Text>
							<RadioGroup
								onChange={(newValue) => setValue("role", newValue as AuthRole)}
								defaultValue={AuthRole.ADMIN}
							>
								<Stack justifyContent={"center"} gap={4} direction="row">
									<Radio value={AuthRole.ADMIN}>Admin</Radio>
									<Radio value={AuthRole.DEVELOPER}>Developer</Radio>
								</Stack>
							</RadioGroup>
						</FormControl>

						<Box pt={4}>
							{/* Submit Button */}
							<Button
								colorScheme={"blue"}
								type="submit"
								width={"full"}
								isDisabled={formState.isSubmitting}
								isLoading={formState.isSubmitting}
							>
								Login
							</Button>
						</Box>
					</Stack>
				</form>
			</Stack>
		</Box>
	)
}
