import { Box, Button, Heading, Stack, Text, useDisclosure } from "@chakra-ui/react"
import { FC } from "react"
import { FiPlus } from "react-icons/fi"
import { AppShell } from "../../../common/wrappers/AppShell"
import { CenteredSpinner } from "../../../common/components/CenterSpinner"
import { BrandAddDrawer } from "./components/BrandAddDrawer"
import { useBrandListQuery } from "./queries/brandListQuery"
import { BrandListView } from "./components/BrandListView"

export const BrandPage: FC = () => {
	const addBrandDrawer = useDisclosure()

	return (
		<AppShell>
			<Stack spacing={4}>
				<Box>
					<Heading>Brands</Heading>
					<Text color={"blackAlpha.700"}>
						Manage all the product brands throughout the system here
					</Text>
				</Box>

				<Box>
					<Button
						size={"sm"}
						variant={"outline"}
						colorScheme={"blue"}
						onClick={addBrandDrawer.onOpen}
						leftIcon={<FiPlus />}
					>
						Add Brand
					</Button>
				</Box>

				<BrandListSection />
			</Stack>

			<BrandAddDrawer {...addBrandDrawer} />
		</AppShell>
	)
}

const BrandListSection: FC = () => {
	const { data, isLoading } = useBrandListQuery()
	if (isLoading) return <CenteredSpinner />
	if (!data?.list) return <Box>No categories added yet</Box>

	return <BrandListView {...data} />
}
