import { Box, Heading } from "@chakra-ui/react"
import { ComponentProps, FC } from "react"

type Props = ComponentProps<typeof Box>

export const AppHeading: FC<Props> = ({ ...rest }) => {
	return (
		<Box
			p={{ base: 0, md: 6 }}
			color={{ base: "blackAlpha.800", md: "whiteAlpha.800" }}
			textTransform="uppercase"
			{...rest}
		>
			<Box>
				{/* TODO: replace heading with BSM logo  */}
				<Heading size={"md"}>Baking Supplies Mumbai</Heading>
			</Box>
			<Box fontSize={"sm"}>Admin Portal</Box>
		</Box>
	)
}
