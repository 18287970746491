import { HashRouter, Route, Routes } from "react-router-dom"
import { AdminHomePage } from "../../pages/Admin"
import { BrandDetailsPage } from "../../pages/Admin/Brand/BrandDetails"
import { BrandPage } from "../../pages/Admin/Brand"
import { CategoryDetailsPage } from "../../pages/Admin/Category/CategoryDetails"
import { CategoryPage } from "../../pages/Admin/Category"
import { DeveloperHomePage } from "../../pages/Developer"
import { LoginPage } from "../../pages/Login"
import { Logout } from "../../pages/Logout"
import { AuthRole } from "../auth"
import { PrivateOutlet } from "./PrivateOutlet"

export function AppRouter() {
	return (
		<HashRouter>
			<Routes>
				<Route index element={<LoginPage />} />
				<Route path="logout" element={<Logout />} />

				<Route path="developer" element={<PrivateOutlet role={AuthRole.DEVELOPER} />}>
					<Route index element={<DeveloperHomePage />} />
				</Route>

				<Route path="admin" element={<PrivateOutlet role={AuthRole.ADMIN} />}>
					<Route index element={<AdminHomePage />} />
					<Route path="category" element={<CategoryPage />} />
					<Route path="category/:id/details" element={<CategoryDetailsPage />} />
					<Route path="brand" element={<BrandPage />} />
					<Route path="brand/:id/details" element={<BrandDetailsPage />} />
				</Route>
			</Routes>
		</HashRouter>
	)
}
