import { AuthRole, getAuth } from "../../../../core/auth"
import { HttpApi } from "../../../../core/http"
import { CategoryV1 } from "../models"

interface RequestBodyShape {
	id?: string
	parentCategoryId?: string | null
}

interface ResponseShape {
	list: CategoryV1[]
	count: number
}

export async function apiCategoryListV1(filter?: RequestBodyShape) {
	const httpApi = new HttpApi<ResponseShape>("post", "category/list/v1")

	const auth = getAuth()
	if (!auth) throw new Error("You are not authenticated")
	if (auth.role !== AuthRole.ADMIN) {
		throw new Error("Only admin can add category")
	}

	return await httpApi.send({ bearerToken: auth.accessToken, body: { filter } })
}
