import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"
import { AdminV1 } from "../models"
import { apiAdminListV1 } from "../apis/apiAdminListV1"

interface StoreShape {
	list: AdminV1[]
	count: number
}

const queryKey = "adminList"

export function useAdminListQuery() {
	return useQuery<StoreShape, Error>(queryKey, async () => {
		const { data } = await apiAdminListV1()
		return data
	})
}

export function useAdminListData() {
	const queryClient = useQueryClient()

	const data = queryClient.getQueryData<StoreShape>(queryKey)
	const state = queryClient.getQueryState<StoreShape>(queryKey)
	const refetch = useCallback(() => queryClient.refetchQueries(queryKey), [queryClient])

	return { data, state, refetch }
}
