import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"
import { BrandV1 } from "../models"
import { apiBrandListV1 } from "../apis/apiBrandListV1"

interface StoreShape {
	list: BrandV1[]
	count: number
}

interface QueryFilter {
	id?: string
}

function getQueryKey(filter?: QueryFilter): [string, ...any] {
	return ["brandList", filter?.id]
}

export function useBrandListQuery(filter?: QueryFilter) {
	const queryKey = getQueryKey(filter)
	return useQuery<StoreShape, Error>(queryKey, async () => {
		const { data } = await apiBrandListV1(filter)
		return data
	})
}

export function useBrandListData(filter?: QueryFilter) {
	const queryKey = getQueryKey(filter)
	const queryClient = useQueryClient()

	const data = queryClient.getQueryData<StoreShape>(queryKey)
	const state = queryClient.getQueryState<StoreShape>(queryKey)
	const refetch = useCallback(
		() => queryClient.refetchQueries(queryKey[0]),
		[queryClient, queryKey],
	)
	const invalidate = useCallback(
		() => queryClient.invalidateQueries(queryKey[0]),
		[queryClient, queryKey],
	)

	return { data, state, refetch, invalidate }
}
