import { Box, Heading, Stack, Text } from "@chakra-ui/react"
import { FC } from "react"
import { formatCounterLabel } from "../../../../common/utils"
import { CategoryV1 } from "../models"

interface Props {
	category: CategoryV1
	isHoverable?: boolean
}

export const CategoryCard: FC<Props> = ({ category, isHoverable }) => {
	const status = category.isPublished ? "Published" : "Not Published"

	const subCategoryCountText = formatCounterLabel(
		category.countSubCategories,
		"Sub-Category",
		"Sub-Categories",
	)
	const productsCountText = formatCounterLabel(
		category.countProductVariants,
		"Product Variant",
		"Product Variants",
	)

	let publishedSubCategoryCountText = ""
	let publishedProductVariantCountText = ""

	if (category.countSubCategories) {
		publishedSubCategoryCountText = category.countPublishedSubCategories
			? ` (${category.countPublishedSubCategories} Published)`
			: " (No Published)"
	}

	if (category.countProductVariants) {
		publishedProductVariantCountText = category.countPublishedProductVariants
			? ` (${category.countPublishedProductVariants} Published)`
			: " (No Published)"
	}

	return (
		<Stack
			key={category.id}
			bgColor="blackAlpha.100"
			borderRadius={"xl"}
			p={4}
			transitionDuration="200ms"
			spacing={1}
			{...(isHoverable && {
				_hover: { cursor: "pointer", shadow: "sm", bgColor: "blackAlpha.200" },
			})}
		>
			<Heading fontSize={"2xl"}>{category.title}</Heading>
			<Box>
				<Text>
					{subCategoryCountText}
					{publishedSubCategoryCountText}
				</Text>
				<Text>
					{productsCountText}
					{publishedProductVariantCountText}
				</Text>
				<Text color={category.isPublished ? undefined : "orange.600"}>{status}</Text>
			</Box>
		</Stack>
	)
}
