import {
	Box,
	Button,
	Flex,
	Heading,
	Link,
	Stack,
	Text,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react"
import { FC } from "react"
import { FiArrowLeft, FiEdit, FiPlus, FiTrash } from "react-icons/fi"
import { GoPrimitiveDot } from "react-icons/go"
import { NavLink, useParams } from "react-router-dom"
import { formatCounterLabel } from "../../../../common/utils"
import { CategoryAddDrawer } from "../components/CategoryAddDrawer"
import { useCategoryListQuery } from "../queries/categoryListQuery"
import { CategoryListView } from "../components/CategoryListView"
import { AppShell } from "../../../../common/wrappers/AppShell"
import { CenteredSpinner } from "../../../../common/components/CenterSpinner"
import { CategoryDeleteDialog } from "./components/CategoryDeleteDialog"
import { CategoryUpdateDrawer } from "./components/CategoryUpdateDrawer"
import { ProductAddDrawer } from "../../Product/components/ProductAddDrawer"

export const CategoryDetailsPage: FC = () => {
	const { id } = useParams()
	if (!id) return <Box>Invalid Category</Box>

	return (
		<AppShell>
			<CategoryDetailsSection id={id} />
		</AppShell>
	)
}

const CategoryDetailsSection: FC<{ id: string }> = ({ id }) => {
	const selfCategoryList = useCategoryListQuery({ id })
	const subCategoryList = useCategoryListQuery({ parentCategoryId: id })

	const addProductDrawer = useDisclosure()
	const addCategoryDrawer = useDisclosure()
	const deleteCategoryDialog = useDisclosure()
	const updateCategoryDrawer = useDisclosure()

	const buttonSize = useBreakpointValue({ base: "md", sm: "sm" })

	const isLoading = selfCategoryList.isLoading || subCategoryList.isLoading
	if (isLoading) return <CenteredSpinner />

	const category = selfCategoryList.data?.list && selfCategoryList.data.list[0]
	if (!category) return <Box>Unable to Load Category</Box>

	const backPageLink = category.parentCategoryId
		? `/admin/category/${category.parentCategoryId}/details`
		: "/admin/category"

	return (
		<Stack spacing={6}>
			<Stack spacing={2}>
				<Link as={NavLink} to={backPageLink}>
					<Flex alignItems={"center"} gap={1}>
						<FiArrowLeft />
						<Text>Back</Text>
					</Flex>
				</Link>

				<Heading>{category.title}</Heading>

				<Text>{category.description || "No description"}</Text>

				<Flex
					gap={{ base: 1, sm: 2 }}
					alignItems={{ base: "left", sm: "center" }}
					direction={{ base: "column", md: "row" }}
				>
					{category.isPublished ? (
						<Text>Published</Text>
					) : (
						<Text color={"orange.600"}>Unpublished</Text>
					)}

					<Box display={{ base: "none", sm: "block" }}>
						<GoPrimitiveDot />
					</Box>

					<Text>
						{formatCounterLabel(
							category.countSubCategories,
							"Sub-Category",
							"Sub-Categories",
						)}
					</Text>

					<Box display={{ base: "none", sm: "block" }}>
						<GoPrimitiveDot />
					</Box>

					<Text>
						{formatCounterLabel(
							category.countProductVariants,
							"Product Variant",
							"Product Variants",
						)}
					</Text>
				</Flex>

				<Box pt={2}>
					<Flex
						gap={{ base: 2, sm: 4 }}
						flexWrap={"wrap"}
						direction={{ base: "column", sm: "row" }}
					>
						<Button
							size={buttonSize}
							variant={"outline"}
							colorScheme={"green"}
							leftIcon={<FiEdit />}
							onClick={updateCategoryDrawer.onOpen}
						>
							Update Details
						</Button>
						<Button
							size={buttonSize}
							variant={"outline"}
							colorScheme={"red"}
							leftIcon={<FiTrash />}
							onClick={deleteCategoryDialog.onOpen}
						>
							Delete this Category
						</Button>
					</Flex>
				</Box>
			</Stack>

			<Flex
				gap={{ base: 2, sm: 4 }}
				flexWrap={"wrap"}
				direction={{ base: "column", sm: "row" }}
			>
				<Button
					size={buttonSize}
					colorScheme={"blue"}
					leftIcon={<FiPlus />}
					onClick={addProductDrawer.onOpen}
				>
					Add Product
				</Button>
				<Button
					size={buttonSize}
					colorScheme={"blue"}
					leftIcon={<FiPlus />}
					onClick={addCategoryDrawer.onOpen}
				>
					Add Sub-Category
				</Button>
			</Flex>

			<Box>
				{subCategoryList.data?.list && <CategoryListView {...subCategoryList.data} />}
				{!subCategoryList.data?.list && <Box>No Sub-Categories</Box>}
			</Box>

			<ProductAddDrawer {...addProductDrawer} category={category} />
			<CategoryAddDrawer {...addCategoryDrawer} parentCategory={category} />
			<CategoryUpdateDrawer {...updateCategoryDrawer} category={category} />
			<CategoryDeleteDialog {...deleteCategoryDialog} category={category} />
		</Stack>
	)
}
