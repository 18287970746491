import { FC } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { AuthRole, getAuth } from "../auth"

export const PrivateOutlet: FC<{ role: AuthRole }> = ({ role }) => {
	const authPayload = getAuth()
	if (!authPayload) return <Navigate to="/" />
	if (authPayload.role === role) return <Outlet />

	/**
	 * //TODO: redirect to special page for unauthorized or forbidden users
	 */
	return <Navigate to="/" />
}
