import { FC, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DeleteItemDialog } from "../../../../../common/components/DeleteItemDialog"
import { useErrorToast, useSuccessToast } from "../../../../../common/hooks/toasts"
import { useBrandListData } from "../../queries/brandListQuery"
import { BrandV1 } from "../../models"
import { apiBrandDeleteV1 } from "../apis/apiBrandDeleteV1"

interface Props {
	brand: BrandV1
	isOpen: boolean
	onClose: () => void
}

export const BrandDeleteDialog: FC<Props> = ({ brand, ...rest }) => {
	const [isLoading, setIsLoading] = useState(false)
	const navigate = useNavigate()
	const { invalidate } = useBrandListData()
	const successToast = useSuccessToast()
	const errorToast = useErrorToast()

	const onDelete = useCallback(() => {
		setIsLoading(true)
		apiBrandDeleteV1({ id: brand.id })
			.then(() => {
				successToast("Brand deleted successfully")
				invalidate()
				rest.onClose()
				navigate("/admin/brand")
			})
			.catch((err) => errorToast(err))
			.finally(() => setIsLoading(false))
	}, [brand, rest, invalidate, errorToast, successToast, navigate])

	return (
		<DeleteItemDialog
			title={`Delete Brand: ${brand.title}`}
			onDelete={onDelete}
			isLoading={isLoading}
			{...rest}
		/>
	)
}
