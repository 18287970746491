import { FC, useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getAdminCategoryDetailsPath } from "../../../helpers"
import { useCategoryListData } from "../../queries/categoryListQuery"
import { CategoryV1 } from "../../models"
import { DeleteItemDialog } from "../../../../../common/components/DeleteItemDialog"
import { useErrorToast, useSuccessToast } from "../../../../../common/hooks/toasts"
import { apiCategoryDeleteV1 } from "../apis/apiCategoryDeleteV1"

interface Props {
	category: CategoryV1
	isOpen: boolean
	onClose: () => void
}

export const CategoryDeleteDialog: FC<Props> = ({ category, ...rest }) => {
	const [isLoading, setIsLoading] = useState(false)
	const navigate = useNavigate()
	const { invalidate } = useCategoryListData()
	const successToast = useSuccessToast()
	const errorToast = useErrorToast()

	const navigatePath = useMemo(() => {
		if (category.parentCategoryId) {
			return getAdminCategoryDetailsPath(category.parentCategoryId)
		}
		return "/admin/category"
	}, [category])

	const onDelete = useCallback(() => {
		setIsLoading(true)
		apiCategoryDeleteV1({ id: category.id })
			.then(() => {
				successToast("Category deleted successfully")
				invalidate()
				rest.onClose()
				navigate(navigatePath)
			})
			.catch((err) => errorToast(err))
			.finally(() => setIsLoading(false))
	}, [category, rest, invalidate, errorToast, successToast, navigate, navigatePath])

	return (
		<DeleteItemDialog
			title={`Delete Category: ${category.title}`}
			onDelete={onDelete}
			isLoading={isLoading}
			{...rest}
		/>
	)
}
