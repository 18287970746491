import { Center } from "@chakra-ui/react"
import { FC } from "react"
import { Navigate } from "react-router-dom"
import { getAuth } from "../../core/auth"
import { LoginForm } from "./components/LoginForm"

export const LoginPage: FC = () => {
	const auth = getAuth()
	if (auth) {
		return <Navigate to={`/${auth.role.toLowerCase()}`} />
	}

	return (
		<Center>
			<LoginForm />
		</Center>
	)
}
