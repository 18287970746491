import { SimpleGrid, Stack, Text } from "@chakra-ui/react"
import { FC } from "react"
import { NavLink } from "react-router-dom"
import { formatCounterLabel } from "../../../../common/utils"
import { BrandV1 } from "../models"
import { BrandCard } from "./BrandCard"

interface Props extends React.ComponentProps<typeof Stack> {
	list: BrandV1[]
	count: number
}

export const BrandListView: FC<Props> = ({ list, count, ...rest }) => {
	return (
		<Stack spacing={2} {...rest}>
			<Text
				textTransform={"uppercase"}
				fontSize="sm"
				fontWeight={"bold"}
				color="gray.500"
				letterSpacing={"wider"}
				pl={2}
			>
				{formatCounterLabel(count, "Brand", "Categories")}
			</Text>
			<SimpleGrid columns={{ base: 1, sm: 2, xl: 3 }} spacing={4}>
				{list.map((brand) => (
					<NavLink key={brand.id} to={`/admin/brand/${brand.id}/details`}>
						<BrandCard isHoverable={true} brand={brand} />
					</NavLink>
				))}
			</SimpleGrid>
		</Stack>
	)
}
