import { HttpApi } from "../../../../../core/http"
import { getAdminAccessToken } from "../../../utils"

interface BodyShape {
	id: string
}

export async function apiBrandDeleteV1(payload: BodyShape) {
	const httpApi = new HttpApi("post", "productBrand/delete/v1")
	const bearerToken = getAdminAccessToken("delete brand")

	return await httpApi.send({ body: payload, bearerToken })
}
