import { HttpApi } from "../../../../core/http"
import { getAdminAccessToken } from "../../utils"
import { ProductV1 } from "../models"

interface BodyShape {
	categoryId: string
	brandId?: string | null
	title: string
	description?: string
}

export async function apiProductAddV1(payload: BodyShape) {
	const httpApi = new HttpApi<ProductV1>("post", "product/add/v1")
	const bearerToken = getAdminAccessToken("add product")

	return await httpApi.send({ bearerToken, body: payload })
}
