import { AuthRole, getAuth } from "../../../core/auth"
import { HttpApi } from "../../../core/http"
import { AdminV1 } from "../models"

interface BodyShape {
	username: string
	password: string
}

export async function apiAdminAddV1(payload: BodyShape) {
	const httpApi = new HttpApi<AdminV1>("post", "admin/add/v1")

	const auth = getAuth()
	if (!auth) throw new Error("You are not authenticated")
	if (auth.role !== AuthRole.DEVELOPER) {
		throw new Error("Only developers can add admins")
	}

	return await httpApi.send({ body: payload, bearerToken: auth.accessToken })
}
