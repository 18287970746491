import { HttpApi } from "../../../core/http"

interface BodyShape {
	username: string
	password: string
}

interface ResponseShape {
	accessToken: string
	refreshToken: string | null
}

export async function apiAdminLoginV1(payload: BodyShape) {
	const httpApi = new HttpApi<ResponseShape>("post", "admin/login/v1")
	return await httpApi.send({ body: payload })
}
