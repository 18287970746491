import { HttpApi } from "../../../../core/http"
import { getAdminAccessToken } from "../../utils"
import { BrandV1 } from "../models"

interface RequestBodyShape {
	id?: string
}

interface ResponseShape {
	list: BrandV1[]
	count: number
}

export async function apiBrandListV1(filter?: RequestBodyShape) {
	const httpApi = new HttpApi<ResponseShape>("post", "productBrand/list/v1")
	const bearerToken = getAdminAccessToken("list brands")

	return await httpApi.send({ bearerToken, body: { filter } })
}
