import { HttpApi } from "../../../../core/http"
import { getAdminAccessToken } from "../../utils"
import { BrandV1 } from "../models"

interface BodyShape {
	title: string
	description?: string
}

export async function apiBrandAddV1(payload: BodyShape) {
	const httpApi = new HttpApi<BrandV1>("post", "productBrand/add/v1")
	const bearerToken = getAdminAccessToken("add a brand")

	return await httpApi.send({ body: payload, bearerToken })
}
