import { Box } from "@chakra-ui/react"
import { FC } from "react"
import { AppShell } from "../../common/wrappers/AppShell"

export const AdminHomePage: FC = () => {
	return (
		<AppShell>
			<Box>Admin Home Page</Box>
		</AppShell>
	)
}
