import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useBreakpointValue,
} from "@chakra-ui/react"
import { FC } from "react"

interface Props {
	title: string
	subtitle?: string | JSX.Element
	isOpen: boolean
	cancelRef?: React.MutableRefObject<null>
	onClose?: Function
	onDelete?: Function
	isLoading?: boolean
	extendedBody?: JSX.Element
}

export const DeleteItemDialog: FC<Props> = ({
	title,
	subtitle,
	isOpen,
	cancelRef,
	onClose,
	onDelete,
	isLoading,
	extendedBody,
}) => {
	const size = useBreakpointValue({ base: "xs", sm: "sm" })

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={() => onClose && onClose()}
			size={size}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{title}
					</AlertDialogHeader>

					<AlertDialogBody>
						{subtitle ?? `Are you sure? You can't undo this action afterwards.`}
						{extendedBody}
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={() => (onClose ? onClose() : null)}>
							Not Now
						</Button>
						<Button
							colorScheme="red"
							onClick={() => onDelete && onDelete()}
							ml={3}
							isLoading={isLoading}
							isDisabled={isLoading}
						>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}
