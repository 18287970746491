import { Box, Button, Flex, Heading, Stack, useDisclosure } from "@chakra-ui/react"
import { FC } from "react"
import { NavLink } from "react-router-dom"
import { AdminAddDrawer } from "./components/AdminAddDrawer"
import { AdminListSection } from "./components/AdminListSection"

export const DeveloperHomePage: FC = () => {
	const addAdminDrawer = useDisclosure()

	return (
		<Stack p={4} spacing={4}>
			<Box>
				<Heading size={"lg"}>Admins</Heading>
			</Box>
			<Flex gap={2}>
				<Button size={"sm"} colorScheme={"blue"} onClick={addAdminDrawer.onOpen}>
					Add Admin
				</Button>
				<NavLink to="/logout">
					<Button size={"sm"}>Logout</Button>
				</NavLink>
			</Flex>
			<AdminListSection />

			<AdminAddDrawer {...addAdminDrawer} />
		</Stack>
	)
}
