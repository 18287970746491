import { SimpleGrid, Stack, Text } from "@chakra-ui/react"
import { FC } from "react"
import { NavLink } from "react-router-dom"
import { formatCounterLabel } from "../../../../common/utils"
import { CategoryV1 } from "../models"
import { CategoryCard } from "./CategoryCard"

interface Props extends React.ComponentProps<typeof Stack> {
	list: CategoryV1[]
	count: number
}

export const CategoryListView: FC<Props> = ({ list, count, ...rest }) => {
	return (
		<Stack spacing={2} {...rest}>
			<Text
				textTransform={"uppercase"}
				fontSize="sm"
				fontWeight={"bold"}
				color="gray.500"
				letterSpacing={"wider"}
				pl={2}
			>
				{formatCounterLabel(count, "Category", "Categories")}
			</Text>
			<SimpleGrid columns={{ base: 1, sm: 2, xl: 3 }} spacing={4}>
				{list.map((category) => (
					<NavLink key={category.id} to={`/admin/category/${category.id}/details`}>
						<CategoryCard isHoverable={true} category={category} />
					</NavLink>
				))}
			</SimpleGrid>
		</Stack>
	)
}
