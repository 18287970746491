import { AuthPayload } from "./models"

const storeKey = "auth"

export function setAuth(authPayload: AuthPayload) {
	localStorage.setItem(storeKey, JSON.stringify(authPayload))
}

export function getAuth(): Readonly<AuthPayload> | null {
	const result = localStorage.getItem(storeKey)
	if (!result) return null
	return JSON.parse(result) as AuthPayload
}

export function deleteAuth() {
	localStorage.removeItem(storeKey)
}
