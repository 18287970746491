import { Box, Flex } from "@chakra-ui/react"
import { ComponentProps, FC } from "react"
import { GoSignOut } from "react-icons/go"
import { IoLanguage } from "react-icons/io5"
import { MdCategory, MdLocationCity } from "react-icons/md"
import { NavLink } from "react-router-dom"
import { AppHeading } from "./AppHeading"
import { NavItem } from "./NavItem"
import { SelectedNavItem } from "./SelectedNavItem"

type Props = ComponentProps<typeof Box>

export const SidebarBody: FC<Props> = ({ role, ...rest }) => {
	return (
		<Box
			as="nav"
			pos="fixed"
			top="0"
			left="0"
			zIndex="sticky"
			h="full"
			pb="10"
			overflowX="hidden"
			overflowY="auto"
			bg="gray.800"
			borderColor="blackAlpha.300"
			borderRightWidth="1px"
			w="60"
			{...rest}
		>
			<AppHeading display={{ base: "none", md: "block" }} />
			<Flex
				direction="column"
				as="nav"
				fontSize="sm"
				color="gray.600"
				aria-label="Main Navigation"
				gap={1}
				pt={{ base: 10, md: 0 }}
			>
				{navItems.map(({ name, icon, link }, i) => {
					const isSelected = link === window.location.hash.split("#")[1]
					return (
						<NavLink to={link} key={i}>
							{isSelected && <SelectedNavItem icon={icon}>{name}</SelectedNavItem>}
							{!isSelected && <NavItem icon={icon}>{name}</NavItem>}
						</NavLink>
					)
				})}
			</Flex>
		</Box>
	)
}

const navItems = [
	{ name: "Dashboard", icon: IoLanguage, link: "/admin" },
	{ name: "Categories", icon: MdCategory, link: "/admin/category" },
	{ name: "Brands", icon: MdLocationCity, link: "/admin/brand" },
	{ name: "Logout", icon: GoSignOut, link: "/logout" },
]
