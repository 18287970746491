import {
	Box,
	Button,
	Flex,
	Heading,
	Link,
	Stack,
	Text,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react"
import { FC } from "react"
import { FiArrowLeft, FiEdit, FiTrash } from "react-icons/fi"
import { GoPrimitiveDot } from "react-icons/go"
import { NavLink, useParams } from "react-router-dom"
import { formatCounterLabel } from "../../../../common/utils"
import { AppShell } from "../../../../common/wrappers/AppShell"
import { CenteredSpinner } from "../../../../common/components/CenterSpinner"
import { useBrandListQuery } from "../queries/brandListQuery"
import { BrandDeleteDialog } from "./components/BrandDeleteDialog"
import { BrandUpdateDrawer } from "./components/BrandUpdateDrawer"

export const BrandDetailsPage: FC = () => {
	const { id } = useParams()
	if (!id) return <Box>Invalid Brand</Box>

	return (
		<AppShell>
			<BrandDetailsSection id={id} />
		</AppShell>
	)
}

const BrandDetailsSection: FC<{ id: string }> = ({ id }) => {
	const { data, isLoading } = useBrandListQuery({ id })
	const buttonSize = useBreakpointValue({ base: "md", sm: "sm" })

	const updateBrandDrawer = useDisclosure()
	const deleteBrandDrawer = useDisclosure()

	if (isLoading) return <CenteredSpinner />

	const backPageLink = "/admin/brand"
	const brand = data?.list && data.list[0]
	if (!brand) return <Box>Unable to Load Brand</Box>

	return (
		<Stack spacing={6}>
			<Stack spacing={2}>
				<Link as={NavLink} to={backPageLink}>
					<Flex alignItems={"center"} gap={1}>
						<FiArrowLeft />
						<Text>Back</Text>
					</Flex>
				</Link>

				<Heading>{brand.title}</Heading>

				<Text maxW={"2xl"} color={"blackAlpha.800"}>
					{brand.description || "No description"}
				</Text>

				<Flex
					gap={{ base: 1, sm: 2 }}
					alignItems={{ base: "left", sm: "center" }}
					direction={{ base: "column", md: "row" }}
				>
					{brand.isPublished ? (
						<Text>Published</Text>
					) : (
						<Text color={"orange.600"}>Unpublished</Text>
					)}

					<Box display={{ base: "none", sm: "block" }}>
						<GoPrimitiveDot />
					</Box>

					<Text>
						{formatCounterLabel(
							brand.countProductVariants,
							"Product Variant",
							"Product Variants",
						)}
					</Text>
				</Flex>

				<Box pt={2}>
					<Flex
						gap={{ base: 2, sm: 4 }}
						flexWrap={"wrap"}
						direction={{ base: "column", sm: "row" }}
					>
						<Button
							size={buttonSize}
							variant={"outline"}
							colorScheme={"green"}
							leftIcon={<FiEdit />}
							onClick={updateBrandDrawer.onOpen}
						>
							Update Details
						</Button>
						<Button
							size={buttonSize}
							variant={"outline"}
							colorScheme={"red"}
							leftIcon={<FiTrash />}
							onClick={deleteBrandDrawer.onOpen}
						>
							Delete this Brand
						</Button>
					</Flex>

					<BrandUpdateDrawer {...updateBrandDrawer} brand={brand} />
					<BrandDeleteDialog {...deleteBrandDrawer} brand={brand} />
				</Box>
			</Stack>
		</Stack>
	)
}
