import { FormControl, Input, Stack, Textarea } from "@chakra-ui/react"
import { ComponentProps, FC } from "react"
import { useForm } from "react-hook-form"
import { DrawerForm } from "../../../../common/components/DrawerForm"
import { useErrorToast, useSuccessToast } from "../../../../common/hooks/toasts"
import { useCategoryListData } from "../queries/categoryListQuery"
import { CategoryV1 } from "../models"
import { apiCategoryAddV1 } from "../apis/apiCategoryAddV1"

interface FormFields {
	title: string
	description: string
}

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	parentCategory?: CategoryV1
}

export const CategoryAddDrawer: FC<Props> = ({ parentCategory, ...rest }) => {
	const errorToast = useErrorToast()
	const successToast = useSuccessToast()
	const { invalidate } = useCategoryListData()

	const { register, handleSubmit, formState, reset } = useForm<FormFields>({
		defaultValues: { title: "", description: "" },
	})

	const onSubmit = handleSubmit(async (formFields) => {
		try {
			await apiCategoryAddV1({ ...formFields, parentCategoryId: parentCategory?.id })
			successToast("Category added successfully")
			invalidate()
			reset()
			rest.onClose()
		} catch (err) {
			errorToast(err)
		}
	})

	return (
		<DrawerForm
			size={"md"}
			headingText={parentCategory ? "Add Sub-Category" : "Add Category"}
			submitLabel="Save"
			onSubmit={onSubmit}
			isSubmitting={formState.isSubmitting}
			{...rest}
		>
			<Stack maxWidth={"md"}>
				<Stack spacing={2}>
					{/* Title */}
					<FormControl>
						<label>Title</label>
						<Input isRequired autoFocus {...register("title")} />
					</FormControl>

					{/* Description */}
					<FormControl>
						<label>Description</label>
						<Textarea {...register("description")} />
					</FormControl>
				</Stack>
			</Stack>
		</DrawerForm>
	)
}
