import {
	Box,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	IconButton,
	useDisclosure,
} from "@chakra-ui/react"
import { ComponentProps, FC } from "react"
import { FiMenu } from "react-icons/fi"
import { AppHeading } from "./AppHeading"
import { SidebarBody } from "./SidebarBody"

type Props = ComponentProps<typeof Box>

export const AppShell: FC<Props> = ({ children, ...rest }) => {
	const sideNavbar = useDisclosure()

	return (
		<Box minH="100vh" bgColor={"gray.50"}>
			<SidebarBody display={{ base: "none", md: "unset" }} />
			<Drawer placement="left" {...sideNavbar}>
				<DrawerOverlay />
				<DrawerContent>
					<SidebarBody w="full" borderRight="none" />
				</DrawerContent>
			</Drawer>

			{/* Mobile Header */}
			<Box ml={{ base: 0, md: 60 }} transition=".3s ease">
				<Flex
					as="header"
					align="center"
					justify="space-between"
					w="full"
					px={4}
					bgColor={"rgba(255, 255, 255, .8)"}
					backdropFilter={"blur(20px)"}
					borderBottomWidth="1px"
					borderColor="blackAlpha.300"
					h="14"
					position={"fixed"}
					top={0}
					zIndex={10}
					display={{ base: "flex", md: "none" }}
				>
					<AppHeading />
					<IconButton
						aria-label="Menu"
						onClick={sideNavbar.onOpen}
						icon={<FiMenu />}
						size="sm"
					/>
				</Flex>
				<Box as="main" p={4} pt={{ base: 20, md: 4 }} {...rest}>
					{children}
				</Box>
			</Box>
		</Box>
	)
}
