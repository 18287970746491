import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"
import { CategoryV1 } from "../models"
import { apiCategoryListV1 } from "../apis/apiCategoryListV1"

interface StoreShape {
	list: CategoryV1[]
	count: number
}

interface QueryFilter {
	id?: string
	parentCategoryId?: string | null
}

function getQueryKey(filter?: QueryFilter): [string, ...any] {
	return ["categoryList", filter?.id, filter?.parentCategoryId]
}

export function useCategoryListQuery(filter?: QueryFilter) {
	const queryKey = getQueryKey(filter)
	return useQuery<StoreShape, Error>(queryKey, async () => {
		const { data } = await apiCategoryListV1(filter)
		return data
	})
}

export function useCategoryListData(filter?: QueryFilter) {
	const queryKey = getQueryKey(filter)
	const queryClient = useQueryClient()

	const data = queryClient.getQueryData<StoreShape>(queryKey)
	const state = queryClient.getQueryState<StoreShape>(queryKey)
	const refetch = useCallback(
		() => queryClient.refetchQueries(queryKey[0]),
		[queryClient, queryKey],
	)
	const invalidate = useCallback(
		() => queryClient.invalidateQueries(queryKey[0]),
		[queryClient, queryKey],
	)

	return { data, state, refetch, invalidate }
}
