import { FormControl, Input, Stack, Textarea } from "@chakra-ui/react"
import { ComponentProps, FC } from "react"
import { useForm } from "react-hook-form"
import { DrawerForm } from "../../../../common/components/DrawerForm"
import { useErrorToast, useSuccessToast } from "../../../../common/hooks/toasts"
import { useBrandListData } from "../queries/brandListQuery"
import { apiBrandAddV1 } from "../apis/apiBrandAddV1"

interface FormFields {
	title: string
	description: string
}

type Props = Omit<ComponentProps<typeof DrawerForm>, "children">

export const BrandAddDrawer: FC<Props> = (props) => {
	const errorToast = useErrorToast()
	const successToast = useSuccessToast()
	const { invalidate } = useBrandListData()

	const { register, handleSubmit, formState, reset } = useForm<FormFields>()

	const onSubmit = handleSubmit(async (formFields) => {
		try {
			await apiBrandAddV1(formFields)
			successToast("Brand added successfully")
			reset()
			invalidate()
			props.onClose()
		} catch (err) {
			errorToast(err)
		}
	})

	return (
		<DrawerForm
			size={"md"}
			headingText={"Add Brand"}
			submitLabel="Save"
			onSubmit={onSubmit}
			isSubmitting={formState.isSubmitting}
			{...props}
		>
			<Stack maxWidth={"md"}>
				<Stack spacing={2}>
					{/* Title */}
					<FormControl>
						<label>Title</label>
						<Input isRequired autoFocus {...register("title")} />
					</FormControl>

					{/* Description */}
					<FormControl>
						<label>Description</label>
						<Textarea {...register("description")} />
					</FormControl>
				</Stack>
			</Stack>
		</DrawerForm>
	)
}
