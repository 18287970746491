import { HttpApi } from "../../../core/http"

interface BodyShape {
	username: string
	password: string
}

interface ResponseShape {
	accessToken: string
	refreshToken: string | null
}

export async function apiDeveloperLoginV1(payload: BodyShape) {
	const httpApi = new HttpApi<ResponseShape>("post", "developer/login/v1")
	return await httpApi.send({ body: payload })
}
