import { Box, Heading, Stack, Text } from "@chakra-ui/react"
import { FC } from "react"
import { formatCounterLabel } from "../../../../common/utils"
import { BrandV1 } from "../models"

interface Props {
	brand: BrandV1
	isHoverable?: boolean
}

export const BrandCard: FC<Props> = ({ brand, isHoverable }) => {
	const status = brand.isPublished ? "Published" : "Not Published"

	const productsCountText = formatCounterLabel(
		brand.countProductVariants,
		"Product Variant",
		"Product Variants",
	)

	let publishedProductVariantCountText = ""
	if (brand.countProductVariants) {
		publishedProductVariantCountText = brand.countPublishedProductVariants
			? ` (${brand.countPublishedProductVariants} Published)`
			: " (No Published)"
	}

	return (
		<Stack
			key={brand.id}
			bgColor="blackAlpha.100"
			borderRadius={"xl"}
			p={4}
			transitionDuration="200ms"
			spacing={1}
			{...(isHoverable && {
				_hover: { cursor: "pointer", shadow: "sm", bgColor: "blackAlpha.200" },
			})}
		>
			<Heading fontSize={"2xl"}>{brand.title}</Heading>
			<Box>
				<Text>
					{productsCountText}
					{publishedProductVariantCountText}
				</Text>
				<Text color={brand.isPublished ? undefined : "orange.600"}>{status}</Text>
			</Box>
		</Stack>
	)
}
