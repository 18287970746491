import axios, { AxiosRequestHeaders } from "axios"
import { HttpStatusCode } from "."
import { apiBaseUrl } from "../configs"
import { HttpMethod } from "./models"
import { HttpError } from "./HttpError"

interface ResponseShape<ResponseDataShape> {
	data: ResponseDataShape
	statusCode: HttpStatusCode
}

export class HttpApi<ResponseDataShape = {}> {
	readonly endpoint: string
	readonly method: HttpMethod

	constructor(method: HttpMethod, endpoint: string) {
		this.endpoint = endpoint
		this.method = method
	}

	async send({
		bearerToken,
		body = null,
		query,
	}: { body?: any; query?: any; bearerToken?: string } = {}): Promise<
		ResponseShape<ResponseDataShape>
	> {
		const url = `${apiBaseUrl}/${this.endpoint}`
		console.log(url)
		const headers: AxiosRequestHeaders = {
			...(bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {}),
		}

		try {
			const response = await axios({
				method: this.method,
				url,
				headers,
				data: body,
				params: query,
			})
			return { statusCode: response.status, data: response.data }
		} catch (err) {
			let statusCode = 500
			let data = { message: "Something went wrong" }

			const errorResponse = axios.isAxiosError(err) ? err?.response : undefined
			if (errorResponse) {
				statusCode = errorResponse.status
				data = errorResponse.data
			}

			if (statusCode === HttpStatusCode.UNAUTHORIZED) {
				window.location.href = "#/logout"
			}

			throw new HttpError(statusCode, data)
		}
	}
}
