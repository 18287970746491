import { Box, Button, Heading, Stack, useDisclosure } from "@chakra-ui/react"
import { FC } from "react"
import { AppShell } from "../../../common/wrappers/AppShell"
import { CenteredSpinner } from "../../../common/components/CenterSpinner"
import { CategoryAddDrawer } from "./components/CategoryAddDrawer"
import { useCategoryListQuery } from "./queries/categoryListQuery"
import { CategoryListView } from "./components/CategoryListView"

export const CategoryPage: FC = () => {
	const addCategoryDrawer = useDisclosure()

	return (
		<AppShell>
			<Stack spacing={4}>
				<Heading>Top Level Categories</Heading>
				<Box>
					<Button
						size={"sm"}
						colorScheme={"blue"}
						onClick={addCategoryDrawer.onOpen}
					>
						Add Top Level Category
					</Button>
				</Box>
				<CategoryListSection />
			</Stack>

			<CategoryAddDrawer
				headingText={"Add Top Level Category"}
				{...addCategoryDrawer}
			/>
		</AppShell>
	)
}

const CategoryListSection: FC = () => {
	const { data, isLoading } = useCategoryListQuery({ parentCategoryId: null })
	if (isLoading) return <CenteredSpinner />
	if (!data?.list) return <Box>No categories added yet</Box>

	return <CategoryListView {...data} />
}
