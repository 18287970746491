import { Box, Table, Thead, Tr, Th, Tbody, Td, Badge } from "@chakra-ui/react"
import { FC } from "react"
import { CenteredSpinner } from "../../../common/components/CenterSpinner"
import { useAdminListQuery } from "../queries/adminListQuery"

export const AdminListSection: FC = () => {
	const { data, isLoading } = useAdminListQuery()

	if (isLoading) return <CenteredSpinner />
	if (!data?.list) return <Box>No admins added yet</Box>

	return (
		<Box border={"1px"} borderColor="gray.100" borderRadius={"lg"}>
			<Table size={"md"}>
				<Thead>
					<Tr>
						<Th>Username</Th>
						<Th>Status</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.list.map((admin) => (
						<Tr key={admin.id}>
							<Td>{admin.username}</Td>
							<Td>
								<Badge colorScheme={"green"}>Active</Badge>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</Box>
	)
}
