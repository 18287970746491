import { AuthRole, getAuth } from "../../../../../core/auth"
import { HttpApi } from "../../../../../core/http"
import { CategoryV1 } from "../../models"

interface BodyShape {
	filter: { id: string }
	update?: { title?: string; description?: string }
}

export async function apiCategoryUpdateV1(payload: BodyShape) {
	const httpApi = new HttpApi<CategoryV1>("post", "category/update/v1")

	const auth = getAuth()
	if (!auth) throw new Error("You are not authenticated")
	if (auth.role !== AuthRole.ADMIN) {
		throw new Error("Only admin can add category")
	}

	return await httpApi.send({ body: payload, bearerToken: auth.accessToken })
}
