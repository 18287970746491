import { FormControl, Input, Stack, Textarea } from "@chakra-ui/react"
import { Select as SearchSelect } from "chakra-react-select"
import { ComponentProps, FC } from "react"
import { useForm } from "react-hook-form"
import { DrawerForm } from "../../../../common/components/DrawerForm"
import { useErrorToast, useSuccessToast } from "../../../../common/hooks/toasts"
import { BrandV1 } from "../../Brand/models"
import { useBrandListQuery } from "../../Brand/queries/brandListQuery"
import { CategoryV1 } from "../../Category/models"
import { useCategoryListData } from "../../Category/queries/categoryListQuery"
import { apiProductAddV1 } from "../apis/apiProductAddV1"

interface FormFields {
	brand: BrandV1 | null
	title: string
	description: string
}

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	category: CategoryV1
}

export const ProductAddDrawer: FC<Props> = ({ category, ...rest }) => {
	const errorToast = useErrorToast()
	const successToast = useSuccessToast()

	const { invalidate: invalidateCategoryList } = useCategoryListData()
	const brandQuery = useBrandListQuery()

	const { register, handleSubmit, formState, reset, setValue } = useForm<FormFields>()

	const onSubmit = handleSubmit(async (formFields) => {
		try {
			await apiProductAddV1({ ...formFields, categoryId: category.id })
			successToast("Product added successfully")

			invalidateCategoryList()
			reset()
			rest.onClose()
		} catch (err) {
			errorToast(err)
		}
	})

	const brandList = brandQuery.data?.list ?? []
	const isBrandListLoading = brandQuery.isLoading

	return (
		<DrawerForm
			size={"md"}
			headingText={`Add Product into ${category.title}`}
			submitLabel="Save"
			onSubmit={onSubmit}
			isSubmitting={formState.isSubmitting}
			{...rest}
		>
			<Stack maxWidth={"md"}>
				<Stack spacing={2}>
					{/* Brand Selection */}
					<FormControl>
						<label>Brand</label>
						<SearchSelect<BrandV1, false>
							options={brandList}
							isLoading={isBrandListLoading}
							getOptionLabel={(option) => option.title}
							getOptionValue={(option) => `${option.id}`}
							onChange={(value) => setValue("brand", value ?? null)}
							isClearable
						/>
					</FormControl>

					{/* Title */}
					<FormControl>
						<label>Title</label>
						<Input isRequired autoFocus {...register("title")} />
					</FormControl>

					{/* Description */}
					<FormControl>
						<label>Description</label>
						<Textarea {...register("description")} />
					</FormControl>
				</Stack>
			</Stack>
		</DrawerForm>
	)
}
